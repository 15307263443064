import { createReducer, on } from '@ngrx/store';
import { saveFiltersSuccess } from './filter.actions';

export const initialState = {};

export const filtersReducer = createReducer(
  initialState,
  on(saveFiltersSuccess, (state, { filters }) => {
    // console.log('Filters: ', filters)
    return ({...state, filters});
  }),
  // on(sessionSuccess, (state, { auth }) => {
  //   // console.log('Session: ', session);
  //   return ({...state, auth: auth});
  // }),
  // on(loginFailure, (state) => ({...state, user: null, auth: null})),
  // on(sessionReset, (state) => ({...state, user: null, auth: null}))
);