import { createReducer, on } from '@ngrx/store';
import { loadAudioSuccess } from './music.actions';

export const initialState = {};

export const musicReducer = createReducer(
  initialState,
  on(loadAudioSuccess, (state, { audios }) => {
    return ({...state, audios: audios});
  }),
  // on(sessionSuccess, (state, { auth }) => {
  //   // console.log('Session: ', session);
  //   return ({...state, auth: auth});
  // }),
  // on(loginFailure, (state) => ({...state, user: null, auth: null})),
  // on(sessionReset, (state) => ({...state, user: null, auth: null}))
);