import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { FilterService } from 'src/app/services/filter/filter.service';
// import { PlayerService } from 'src/app/services/player/player.service';

@Injectable()
export class FiltersEffects {
    saveFilters$ = createEffect(() =>
        this.actions$.pipe(
            ofType('[Filter Service] Save Filters'),
            exhaustMap((action: any) => {
                return this.filterService.save(action.filters).pipe(
                    map((saveResponse: any) => {
                        return ({ type: '[Filter Service] Save Filters Success', filters: saveResponse })
                    }),
                    catchError(error => of(({ type: '[Filter Service] Save Filters Failure', error })))
                )
            }
            )
        )
    );

    loadFilters$ = createEffect(() =>
        this.actions$.pipe(
            ofType('[Filter Service] Load Filters'),
            exhaustMap(() => {
                return this.filterService.getAll().pipe(
                    map((filters: any) => {
                        return ({ type: '[Filter Service] Load Filters Success',  filters })
                    }),
                    catchError(error => of(({ type: '[Filter Service] Load Filters Failure', error })))
                )
            })
        )
    );

    // filterList$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType('[Filter SelectList] Filter List'),
    //         exhaustMap((action: any) => {
    //             return this.playerService.filter(action.filter).pipe(
    //                 map((audios: any) => {
    //                     return ({ type: '[Audio Service] Load Audio Success', audios })
    //                 }),
    //                 catchError(error => of(({ type: '[Audio Service] Load Audio Failure', error })))
    //             )
    //         })
    //     )
    // );

    constructor(
        private actions$: Actions,
        private filterService: FilterService,
        // private playerService: PlayerService,
    ) { }
}