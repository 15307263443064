import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import * as moment from "moment";
import { IAudioStream } from "src/app/interfaces/iaudio-stream";
import { IPlaylistState, IStreamState } from "src/app/interfaces/istream-state";


@Injectable({
  providedIn: 'root'
})
export class AudioStreamService {

  private state: IStreamState = {
    playing: false,
    paused: false,
    stopped: true,
    readableCurrentTime: '',
    readableDuration: '',
    duration: undefined,
    currentTime: undefined,
    canplay: false,
    error: false,
    _id: undefined,
    _title: '',
    _artist: '',
  };

  public cover_art!: string;
  private stop$ = new Subject();
  private audioObj = new Audio();
  audioEvents = [
    "ended",
    "error",
    "play",
    "playing",
    "pause",
    "timeupdate",
    "canplay",
    "loadedmetadata",
    "loadstart"
  ];

  private stateChange: BehaviorSubject<IStreamState> = new BehaviorSubject(this.state);
  private playerMode$: BehaviorSubject<boolean> = new BehaviorSubject(false)

  private updateStateEvents(event: Event): void {
    switch (event.type) {
      case "canplay":
        this.state.duration = this.audioObj.duration;
        this.state.readableDuration = this.formatTime(this.state.duration);
        this.state.canplay = true;
        break;
      case "playing":
        this.state.playing = true;
        this.state.paused = false;
        break;
      case "pause":
        this.state.playing = false;
        this.state.paused = true;
        break;
      case "timeupdate":
        this.state.currentTime = this.audioObj.currentTime;
        this.state.readableCurrentTime = this.formatTime(this.state.currentTime);
        break;
      case "stopped":
        this.resetState();
        this.state.stopped = true;
        break;
      case "error":
        this.resetState();
        this.state.error = true;
        break;
    }
    this.stateChange.next(this.state);
  }

  private resetState() {
    this.state = {
      playing: false,
      paused: false,
      stopped: true,
      readableCurrentTime: '',
      readableDuration: '',
      duration: undefined,
      currentTime: undefined,
      canplay: false,
      error: false,
      _id: undefined,
      _artist: undefined,
      _title: undefined
    }
  }

  private streamObservable(url: string, id?: string, title?: string, artist?: string, isPlaylist?: IPlaylistState) {
    return new Observable(observer => {
      // Play audio
      if (isPlaylist) this.state.isPlaylist = isPlaylist;
      this.state._id = id;
      this.state._artist = artist;
      this.state._title = title;
      this.state.stopped = false;
      this.audioObj.src = url;
      this.audioObj.load();
      this.audioObj.play();
  
      const handler = (event: Event) => {
        this.updateStateEvents(event);
        observer.next(event);
      };
  
      this.addEvents(this.audioObj, this.audioEvents, handler);
      return () => {
        // Stop Playing
        this.audioObj.pause();
        this.audioObj.currentTime = 0;
        // remove event listeners
        this.removeEvents(this.audioObj, this.audioEvents, handler);
        // reset state
        this.resetState();
      };
    });
  }

  private addEvents(obj: any, events: any, handler: any) {
    events.forEach((event: any) => {
      obj.addEventListener(event, handler);
    });
  }

  private removeEvents(obj: any, events: any, handler: any) {
    events.forEach((event: any) => {
      obj.removeEventListener(event, handler);
    });
  }

  playStream(url: any, id: string, title: string, artist: string, isPlaylist?: IPlaylistState) {
    // this.fullPlayerMode();
    return this.streamObservable(url, id, title, artist, isPlaylist).pipe(takeUntil(this.stop$));
  }



  play() {
    this.audioObj.play();
  }

  pause() {
    this.audioObj.pause();
  }

  stop() {
    this.stop$.next('');
    this.resetState();
    this.stateChange.next(this.state);
  }

  stopAll() {
    this.stop$.next('');
    this.resetState();
    this.stateChange.next(this.state);
  }

  seekTo(seconds: any) {
    this.audioObj.currentTime = seconds;
  }

  formatTime(time: number, format: string = "HH:mm:ss") {
    const momentTime = time * 1000;
    return moment.utc(momentTime).format(format);
  }

  getState(): Observable<IStreamState> {
    return this.stateChange.asObservable();
  }

  miniPlayerMode() {
    this.playerMode$.next(false);
  }

  fullPlayerMode() {
    this.playerMode$.next(true);
  }

  getPlayer(): Observable<boolean> {
    return this.playerMode$.asObservable();
  }
}
