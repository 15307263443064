import { Injectable } from '@angular/core';
import { IPlaylist } from 'src/app/interfaces/iplaylist';
import { environment } from '../../../environments/environment';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class PlaylistService {
  headers: any;
  private url: string = environment.playlist_service_url;

  constructor(private http: HttpService) {
    this.headers = {'Content-Type': 'application/json', withCredentials: true};
  }

  getAllPlaylist() {

    return this.http.get(`${this.url}/playlists`);
  }

  getPlaylist(playlist: IPlaylist) {
    return this.http.get(`${this.url}/playlist/${playlist.id}`);
  }

  savePlaylist(playlist: IPlaylist) {
    return this.http.post(`${this.url}/playlist`, playlist);
  }

  updatePlaylist(playlist: IPlaylist) {
    const { songs, name, id } = playlist;
    return this.http.update(`${this.url}/playlist`, {name, songs}, <string>id);
  }

  deletePlaylist(id: string) {
    return this.http.delete(`${this.url}/playlist`, id);
  }
}
