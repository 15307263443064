import { createAction, props } from '@ngrx/store';
import { IAudio } from 'src/app/interfaces/iaudio';
import { IMedia } from 'src/app/interfaces/imedia';
// { title: string; discription?: string; artist: string, year: number, genre?: string, song: string }
export const saveMedia = createAction(
  '[Audio musicForm] Save Media',
  props<{ media: IMedia }>()
);

export const deleteMedia = createAction(
  '[Audio Service] Delete Media',
  props<{ audio: IAudio }>()
);

export const deleteAudio = createAction(
  '[Audio Service] Delete Audio',
  props<{ audio: IAudio }>()
);

export const deleteMediaFailure = createAction(
  '[Audio Service] Media Delete Failure'
);

export const deleteAudioFailure = createAction(
  '[Audio Service] Audio Delete Failure'
);

export const saveMediaSuccess = createAction(
  '[Audio Service] Media Save Success',
  props<{ saveMusicResponse: any }>()
);

export const saveMediaFailure = createAction(
  '[Audio Service] Media Save Failure'
);

export const saveAudio = createAction(
  '[Audio Service] Save Audio',
  props<{ audio: IAudio }>()
)

export const saveAudioSuccess = createAction(
  '[Audio Service] Audio Save Success',
  props<{ saveMusicResponse: any }>()
);

export const saveAudioFailure = createAction(
  '[Audio Service] Audio Save Failure'
);

export const loadAudio = createAction(
  '[Audio Service] Load Audio'
);

export const loadAudioSuccess = createAction(
  '[Audio Service] Load Audio Success',
  props<{ audios: IAudio[] }>()
);

export const loadAudioFailure = createAction(
  '[Audio Service] Load Audio Failure'
);

export const loadMusic = createAction(
  '[Audio Service] Load Music'
);

export const loadMusicSuccess = createAction(
  '[Audio Service] Load Music Success'
);


export const audioReset = createAction(
  '[Audio Sevice] Reset Audio'
);