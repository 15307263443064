import { Injectable } from '@angular/core';
import { map, Observable, of, tap } from 'rxjs';
import { FileElement } from 'src/app/classes/file-element';
import { environment } from '../../../environments/environment';
import { TextboxElement } from 'src/app/classes/textbox-element';
import { FormElements } from 'src/app/interfaces/form-elements';
import { IMedia } from 'src/app/interfaces/imedia';
import { HttpService } from '../http/http.service';
import { Store } from '@ngrx/store';
import { deleteMedia } from 'src/app/storage/states/music/music.actions';
import { IAudio } from 'src/app/interfaces/iaudio';
// import { addAllMedia, addMedia } from 'src/app/storage/state/actions/media.actions';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  private url: string = environment.media_service_url;

  constructor(
    private http: HttpService, 
    // private state: Store<State>
  ) { }

  getMediaFormElements() {
    const elements: FormElements<string>[] = [
      new FileElement({ key: 'audio', label: 'Audio', type: 'file', required: true, order: 1 }),
      new TextboxElement({ key: 'name', label: 'Title', type: 'text', required: true, order: 1 }),
    ];

    return of(elements.sort((a, b) => a.order - b.order));
  }

  getUploadUrl(filename: any[]) {
    const url = 'https://4gikutkp22.execute-api.eu-west-2.amazonaws.com/dev';
    return this.http.post(`${url}/media/url`, filename);
  }

  getSingedUrl(id: string) {
    console.log('this url: ', this.url)
    return this.http.get(`${this.url}/singedUrl/${id}`);
  }

  s3upload(url: string, media: any) {
    const {name, size, type} = media;
    const fd = new FormData();
    fd.append(name, media);
    return this.http.put(url, fd, {'Content-Type': type});
  }

  multipartS3Upload(url: string, file: any) {
    return this.http.put(url, file, {'Content-Type': 'multipart/form-data'});
  }

  // s3Base64(url: string, media: any, type: any) {
  //   return this.http.put(url, media, {'Content-Encoding': 'base64'})
  // }

  // s3uploadPromise(url: string, media: any) {
  //   return new Promise((resolve, reject) => {
  //     try {
  //       const {name, size, type} = media;
  //       const fd = new FormData();
  //       fd.append(name, media);
  //       this.http.put(url, fd, {'Content-Type': type}).subscribe((res: any) => {
  //         console.log('Res: ', res)
  //         resolve(res)
  //       });
  //     } catch (error) {
  //       reject(error)
  //     }
  //   })
  // }

  getS3Url(id: string) {
    // console.log('this url: ', this.url)
    const url = 'https://4gikutkp22.execute-api.eu-west-2.amazonaws.com/dev/media/url';
    return this.http.get(`${url}/${id}`);
  }

  save(media: IMedia) {
    const url = 'https://4gikutkp22.execute-api.eu-west-2.amazonaws.com/dev/metadata';
    return this.http.post(url, media);
  }

  upload(media: IMedia) {
    return this.http.post(`${this.url}/media`, media);
  }

  uploadStream(media: any) {
    // console.log('Media: ', media)
    const fd = new FormData();
    // Object.keys(media).forEach(name => {
    //   fd.append(name, media[name])
    // })

    Object.keys(media).forEach(name => {
      console.log('Files: ', media[name].files[0])
      fd.append(name, media[name].files[0])
    })

    // fd.append('file', media);

    // Object.keys(this.form.controls).forEach(formControlName => { 
    //   formData.append(formControlName,  this.form.get(formControlName).value);    
    // });     

    console.log('Form data: ', fd)
    return this.http.post(`${this.url}/stream/upload`, fd, {});
  }

  getStream(id: string) {
    return this.http.get(`${this.url}/stream/${id}`, {responseType: 'arraybuffer'})
  }

  getMedia(id: string) {
    return this.http.get(`${this.url}/media/${id}`)
  }

  getAudioFile(id: string) {
    return this.http.get(`${this.url}/audio/${id}`)
  }

  getAllMedia() {
    return this.http.get(`${this.url}/medias`);
  }

  deleteMedia(audio: IAudio) {
    console.log('Audio to be deleted: ', audio)
    return this.http.delete(`${this.url}/media`, audio.song)
    .pipe(map((res: any) => {
      return audio
    }))
  }
}
