import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TableListComponent } from './table-list/table-list.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { GridListComponent } from './grid-list/grid-list.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { LayoutModule } from '@angular/cdk/layout';
import { MatSliderModule } from '@angular/material/slider';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { ElementsComponent } from './dynamic-form/elements/elements.component';
import { MatSelectModule } from '@angular/material/select';
import { RegisterFormComponent } from './register-form/register-form.component';
import { CarouselComponent } from './carousel/carousel.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AudioUploadFormComponent } from './audio-upload-form/audio-upload-form.component';
import { AudioFormComponent } from './audio-form/audio-form.component';
import { MediaFormComponent } from './media-form/media-form.component';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { MusicFormComponent } from './music-form/music-form.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MessageCardComponent } from './message-card/message-card.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SelectionListComponent } from './selection-list/selection-list.component';
import { MatListModule } from '@angular/material/list';
import { MyCounterComponent } from './my-counter/my-counter.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AddToPlaylistComponent } from './add-to-playlist/add-to-playlist.component';
import { AudioListComponent } from './audio-list/audio-list.component';
import { AudioPlayerComponent } from './audio-player/audio-player.component';
import { MiniAudioPlayerComponent } from './mini-audio-player/mini-audio-player.component';
import { AlbumFormComponent } from './album-form/album-form.component';


@NgModule({
  declarations: [
    TableListComponent,
    GridListComponent,
    DynamicFormComponent,
    LoginFormComponent,
    ElementsComponent,
    RegisterFormComponent,
    CarouselComponent,
    AudioUploadFormComponent,
    AudioFormComponent,
    MediaFormComponent,
    MusicFormComponent,
    MessageCardComponent,
    ProgressBarComponent,
    SelectionListComponent,
    MyCounterComponent,
    AddToPlaylistComponent,
    AudioListComponent,
    AudioPlayerComponent,
    MiniAudioPlayerComponent,
    AlbumFormComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatMenuModule,
    LayoutModule,
    MatSliderModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatTabsModule,
    MatToolbarModule,
    MaterialFileInputModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatDialogModule
  ],
  exports: [
    TableListComponent,
    GridListComponent,
    LoginFormComponent,
    RegisterFormComponent,
    CarouselComponent,
    AudioUploadFormComponent,
    MusicFormComponent,
    MessageCardComponent,
    ProgressBarComponent,
    SelectionListComponent,
    MyCounterComponent,
    AudioFormComponent,
    AudioListComponent,
    AudioPlayerComponent,
    MiniAudioPlayerComponent,
    AlbumFormComponent
  ]
})
export class ComponentsModule { }
