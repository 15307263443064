import { createReducer, on } from '@ngrx/store';
import { IAlbum } from 'src/app/interfaces/ialbum';
import { IPlaylist } from 'src/app/interfaces/iplaylist';
import { loadAlbumSuccess } from './album.actions';

export interface AlbumState {
    albums: Array<IAlbum>;
}

export const initialState: AlbumState = {
    albums: [],
};

export const albumReducer = createReducer(
  initialState,
  on(loadAlbumSuccess, (state, { albums }) => {
    // console.log('Playlists: ', playlists)
    return ({albums: albums.map((album: any) => {return {...album, songs: JSON.parse(album.songs)}})});
  }),

//   on(loadPlaylistSuccess, (state, { playlists }) => ({
//     ...state,
//     playlists: [...playlists],
//   })),

  // on(sessionSuccess, (state, { auth }) => {
  //   // console.log('Session: ', session);
  //   return ({...state, auth: auth});
  // }),
  // on(loginFailure, (state) => ({...state, user: null, auth: null})),
  // on(sessionReset, (state) => ({...state, user: null, auth: null}))
);