import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './pages/not-found/not-found.component';

const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( (m) => m.DashboardModule)
  },
  {
    path: 'overview',
    loadChildren: () => import('./pages/overview/overview.module').then( (m) => m.OverviewModule)
  },
  {
    path: 'audio',
    loadChildren: () => import('./pages/audio/audio.module').then( (m) => m.AudioModule)
  },
  {
    path: 'video',
    loadChildren: () => import('./pages/video/video.module').then( (m) => m.VideoModule)
  },
  {
    path: 'favourite',
    loadChildren: () => import('./pages/favourite/favourite.module').then( (m) => m.FavouriteModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then( (m) => m.SearchModule)
  },
  {
    path: '',
    loadChildren: () => import('./pages/landing/landing.module').then( (m) => m.LandingModule)
  },
  { path: '**', title: '404', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
