import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { LocalStorageService } from 'src/app/storage/local/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  private filters_key = "cam_filters";
  set filters(filters: string[]) {
    const _filters = new Set(filters);
    this.localStorage.save(this.filters_key, [..._filters]);
  }

  get filters() {
    const filters = this.localStorage.get(this.filters_key);
    return filters && filters != null ? filters : [];
  }

  constructor(private localStorage: LocalStorageService) { }

  save(filters: string[]) {
    this.filters = filters;
    return of(this.filters)
  }

  getAll() {
    return of(this.filters)
  }
}
