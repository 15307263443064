import { createAction, props } from '@ngrx/store';
import { IAudio } from 'src/app/interfaces/iaudio';
// import { IMedia } from 'src/app/interfaces/imedia';
import { ITrack } from 'src/app/interfaces/itrack';

export const queueNextAudio = createAction(
  '[Player playAllBtn] Queue Next Audio'
);

export const queuePrevAudio = createAction(
  '[Player playAllBtn] Queue Prev Audio'
);

export const queueSuccess = createAction(
    '[Player Service] Queue Success',
    props<{ audio: IAudio }>()
);

export const addTrack = createAction(
  '[Player Service] Add Track',
  props<{ track: ITrack }>()
);

export const playAllSuccess = createAction(
  '[Player Service] Play All Success',
);

export const playAllFailure = createAction(
  '[Player Service] Play All Failure',
);

export const clearPlayAll = createAction(
  '[Player Sevice] Reset Play All',
);

export const clearPlayAllSuccess = createAction(
  '[Player Service] Reset Play All Success',
  props<{ tracks: ITrack }>()
);

export const clearPlayAllFailure = createAction(
  '[Player Service] Reset Play All Failure',
);


