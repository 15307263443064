import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from '../services/user/user.service';
import { AuthService } from '../services/auth/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private userService: UserService, private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const matchUrl = request.url.split('/').includes('account');

    // console.log('Requests: ', request)

    if (request.url.split('/').includes('account')) {
      // Clone the request and set the new header in one step.
      const authReq = request.clone({
        setHeaders: { 'x-api-key': this.userService.api_key },
      });
      return next.handle(authReq);
    }

    if (request.url.split('/').includes('refresh')) {
      // Clone the request and set the new header in one step.
      const authReq = request.clone({
        setHeaders: { 'Authorization': `Bearer ${this.authService.session.auth.token}` },
      });
      return next.handle(authReq);
    }

    // Token url
    if (request.url.split('/').includes('token')) {
      // Clone the request and set the new header in one step.
      const authReq = request.clone({
        setHeaders: { 'x-api-key': this.userService.api_key },
      });
      return next.handle(authReq);
    }

    // Media url
    if (request.url.split('/').includes('media') && this.authService.session.auth.token) {
      // Clone the request and set the new header in one step.
      const authReq = request.clone({
        setHeaders: { 'Authorization': `Bearer ${this.authService.session.auth.token}` },
      });
      return next.handle(authReq);
    }

    // Medias url
    if (request.url.split('/').includes('medias') && this.authService.session.auth.token) {
      // Clone the request and set the new header in one step.
      const authReq = request.clone({
        setHeaders: { 'Authorization': `Bearer ${this.authService.session.auth.token}` },
      });
      return next.handle(authReq);
    }

    // Medias url
    if (request.url.split('/').includes('metadata') && this.authService.session.auth.token) {
      // Clone the request and set the new header in one step.
      const authReq = request.clone({
        setHeaders: { 'Authorization': `Bearer ${this.authService.session.auth.token}` },
      });
      return next.handle(authReq);
    }

    // Stream url
    if (request.url.split('/').includes('stream') && this.authService.session.auth.token) {
      // Clone the request and set the new header in one step.
      const authReq = request.clone({
        setHeaders: { 'Authorization': `Bearer ${this.authService.session.auth.token}` },
      });
      return next.handle(authReq);
    }

    // Music url
    if (request.url.split('/').includes('audio') && this.authService.session.auth.token) {
      // Clone the request and set the new header in one step.
      const authReq = request.clone({
        setHeaders: { 'Authorization': `Bearer ${this.authService.session.auth.token}` },
      });
      return next.handle(authReq);
    }
    if (request.url.split('/').includes('audios') && this.authService.session.auth.token) {
      // Clone the request and set the new header in one step.
      const authReq = request.clone({
        setHeaders: { 'Authorization': `Bearer ${this.authService.session.auth.token}` },
      });
      return next.handle(authReq);
    }
    // Music url
    if (request.url.split('/').includes('singedUrl') && this.authService.session.auth.token) {
      // Clone the request and set the new header in one step.
      const authReq = request.clone({
        setHeaders: { 'Authorization': `Bearer ${this.authService.session.auth.token}` },
      });
      return next.handle(authReq);
    }
    // Playlist url
    if (request.url.split('/').includes('playlist') && this.authService.session.auth.token) {
      // Clone the request and set the new header in one step.
      const authReq = request.clone({
        setHeaders: { 'Authorization': `Bearer ${this.authService.session.auth.token}` },
      });
      return next.handle(authReq);
    }
    if (request.url.split('/').includes('playlists') && this.authService.session.auth.token) {
      // Clone the request and set the new header in one step.
      const authReq = request.clone({
        setHeaders: { 'Authorization': `Bearer ${this.authService.session.auth.token}` },
      });
      return next.handle(authReq);
    }

    if (request.url.split('/').includes('album') && this.authService.session.auth.token) {
      // Clone the request and set the new header in one step.
      const authReq = request.clone({
        setHeaders: { 'Authorization': `Bearer ${this.authService.session.auth.token}` },
      });
      return next.handle(authReq);
    }

    if (request.url.split('/').includes('albums') && this.authService.session.auth.token) {
      // Clone the request and set the new header in one step.
      const authReq = request.clone({
        setHeaders: { 'Authorization': `Bearer ${this.authService.session.auth.token}` },
      });
      return next.handle(authReq);
    }
    return next.handle(request);
  }
}



// if (request.url.split('/').includes('account') || request.url.split('/').includes('token')) {
//   // Clone the request and set the new header in one step.
//   const authReq = request.clone({
//     setHeaders: { 'x-api-key': this.userService.api_key },
//   });
//   return next.handle(authReq);
// } else {
//   // Clone the request and set the new header in one step.
//   const authReq = request.clone({
//     setHeaders: { 'Authorization': `Bearer ${this.authService.session.auth.token}` },
//   });
//   return next.handle(authReq);
// }