import { createReducer, on } from '@ngrx/store';
import { IPlaylist } from 'src/app/interfaces/iplaylist';
import { loadPlaylistSuccess } from './playlist.actions';

export interface PlaylistState {
    playlists: Array<IPlaylist>;
}

export const initialState: PlaylistState = {
    playlists: [],
};

export const playlistReducer = createReducer(
  initialState,
  on(loadPlaylistSuccess, (state, { playlists }) => {
    // console.log('Playlists: ', playlists)
    return ({playlists: playlists.map((playlist: any) => {return {...playlist, songs: JSON.parse(playlist.songs)}})});
  }),

//   on(loadPlaylistSuccess, (state, { playlists }) => ({
//     ...state,
//     playlists: [...playlists],
//   })),

  // on(sessionSuccess, (state, { auth }) => {
  //   // console.log('Session: ', session);
  //   return ({...state, auth: auth});
  // }),
  // on(loginFailure, (state) => ({...state, user: null, auth: null})),
  // on(sessionReset, (state) => ({...state, user: null, auth: null}))
);