import { Component, OnInit, Output } from '@angular/core';
import { IAudio } from 'src/app/interfaces/iaudio';
import { IStreamState } from 'src/app/interfaces/istream-state';
import { ITrack } from 'src/app/interfaces/itrack';
import { AudioPlaylistService } from 'src/app/services/player/audio-playlist.service';
import { AudioStreamService } from 'src/app/services/player/audio-stream.service';

@Component({
  selector: 'audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss']
})
export class AudioPlayerComponent implements OnInit {

  @Output() track!: ITrack;
  @Output() audioPlayer!: IStreamState;
  cover_art!: string;
  playing$: boolean = false;
  previous$: boolean = false;
  playlistNext$!: IAudio[];
  playlistPrev$!: IAudio[];

  constructor(
    private streamService: AudioStreamService,
    private audioPlaylistService: AudioPlaylistService
  ) { }

  ngOnInit(): void {
    this.streamService.getState().subscribe(state => {this.audioPlayer = state})
    this.cover_art = this.streamService.cover_art;
  }

  // Stop music
  stopPlay(): void {
    this.streamService.stopAll();
  }

  enablePrev(): boolean {
    if (this.audioPlayer.isPlaylist) if (this.audioPlayer.isPlaylist.previous || this.audioPlayer.isPlaylist.previous == 0) return true;
    return false;
  }

  enableNext(): boolean {
    let enable = this.audioPlayer.isPlaylist && this.audioPlayer.isPlaylist.next ? true : false;
    return enable;
  }

  toggleMusic(): void {
    if (this.audioPlayer.playing) {
      this.streamService.pause();
    } else this.streamService.play();
  }

  seek(change: any): void {
    this.streamService.seekTo(change.value)
  }

  next(): void {
    if (this.audioPlayer?.isPlaylist?.next) this.audioPlaylistService.playNext(this.audioPlayer.isPlaylist.next);
  }

  prev(): void {
    if (this.audioPlayer?.isPlaylist?.previous || this.audioPlayer?.isPlaylist?.previous == 0) this.audioPlaylistService.playPrevious(this.audioPlayer.isPlaylist.previous);
  }

  miniPlayer(): void {
    this.streamService.miniPlayerMode()
  }
}
