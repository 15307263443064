import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { IAuth } from 'src/app/interfaces/iauth';
import { ILoginResponse } from 'src/app/interfaces/ilogin-response';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AudioPlaylistService } from 'src/app/services/player/audio-playlist.service';
 
@Injectable()
export class SessionEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType('[Session loginForm] Login'),
      exhaustMap((action: any) => {
          return this.authService.login({email: action.email, password: action.password}).pipe(
            map((loginResponse: ILoginResponse) => {
              return ({ type: '[Session Service] Login Success', loginResponse })
            }),
            catchError(error => of(({ type: '[Session Service] Login Failure', error })))
          )
        }
      )
    )
  );

  session$ = createEffect(() =>
    this.actions$.pipe(
      ofType('[Session Service] Login Success'),
      exhaustMap((action: any) => {
          console.log('Create Session obj: ', action.loginResponse)
          return this.authService.createSession(action.loginResponse).pipe(
            map((auth: IAuth) => {
              // this.router.navigate(['/audio']);
              return ({ type: '[Session Service] Auth Success', auth })
            }),
            catchError(error => of(({ type: '[Session Service] Auth Failure', error })))
          )
        }
      )
    )
  );

  // refreshSession$ = createEffect(() => 
  //   this.actions$.pipe(
  //     ofType('[Session Service] Refresh Session'),
  //     exhaustMap((action: any) => {
  //       console.log('Refresh token action result: ');
  //       return this.authService.refreshToken().pipe(
  //         map((loginResponse: ILoginResponse) => {
  //           return ({ type: '[Session Service] Login Success', loginResponse })
  //         }),
  //         catchError(error => of(({ type: '[Session Service] Login Failure', error })))
  //       )
  //     })
  //   )
  // )

  loadSession$ = createEffect(() => 
    this.actions$.pipe(
      ofType('[Session appComponent] Load Session'),
      exhaustMap(() => {
        return this.authService.refreshToken().pipe(
          map((loginResponse: ILoginResponse) => {
            return ({ type: '[Session Service] Login Success', loginResponse })
          }),
          catchError(error => of(({ type: '[Session Service] Login Failure', error })))
        )
      })
    )
  );

  deleteSession$ = createEffect(() => 
    this.actions$.pipe(
      ofType('[Session logoutBtn] Logout Session'),
      exhaustMap((action: any) => {
          console.log('Logout of session effect: ', action)
          return this.authService.logout(action.user).pipe(
            map(() => {
              return ({ type: '[Session Sevice] Reset Session Success'})
            }),
            catchError(error => of(({ type: '[Session Service] Logout Failure', error })))
          )
      })
    )
  );

  loadSessionError = createEffect(() => 
      this.actions$.pipe(
        ofType('[Session Service] Login Failure'),
        exhaustMap((action: any) => {
          console.log('Login Error: ', action);
          this.audioPlaylistService.clearPlaylist();
          return this.authService.logout(this.authService.session.user).pipe(
            map(() => {
              // this.router.navigate(['/']);
              return ({ type: '[Session Sevice] Reset Session Success'})
            }),
            catchError(error => of(({ type: '[Session Service] Logout Failure', error })))
          )
        })
      )
  )
      
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private audioPlaylistService: AudioPlaylistService
  ) {}
}