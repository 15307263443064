<mat-toolbar color="accent" class="p-0">
    <div class="d-flex w-100">
        <div class="p-2 flex-shrink-1">
            <button [disabled]="audioPlayer.stopped || audioPlayer.error" (click)="toggleMusic()" mat-raised-button class="mt-1">
                <mat-icon id="player-play">{{ audioPlayer && audioPlayer.playing ? 'pause' : 'play_arrow' }}</mat-icon>
            </button>
        </div>
        <div class="p-2 flex-shrink-1">
            <button [disabled]="audioPlayer.stopped || audioPlayer.error" (click)="stopMusic()" mat-raised-button class="mt-1">
                <mat-icon id="player-stop">stop</mat-icon>
            </button>
        </div>
        <div class="w-100" (click)="fullPlayerMode()">
            <mat-toolbar color="accent" class="media-action-bar">
                <span class="nav-spacer"></span>
                <div>
                    <mat-card class="py-0">{{ audioPlayer && audioPlayer.readableCurrentTime ? audioPlayer.readableCurrentTime : '00:00' }}</mat-card>
                </div>
            </mat-toolbar>
        </div>
    </div>
</mat-toolbar>