import { createReducer, on } from '@ngrx/store';
import { ITrack } from 'src/app/interfaces/itrack';
import { addTrack, clearPlayAllSuccess } from './player.actions';


export interface TrackState {
    tracks: Array<ITrack>;
}

export const initialState: TrackState = {
    tracks: []
};

export const playerReducer = createReducer(
  initialState,
  on(addTrack, (state, { track }) => {
    return ({...state, tracks: [...state.tracks, track]});
  }),
  on(clearPlayAllSuccess, () => {
    return ({tracks: []})
  })
);