import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { PlaylistService } from 'src/app/services/playlist/playlist.service';

@Injectable()
export class PlaylistEffects {

    savePlaylist$ = createEffect(() => 
        this.actions$.pipe(
            ofType('[Playlist Component] Save Playlist'),
            exhaustMap((action: any) => {
                // console.log('Playlist in effect: ', action);
                return this.playlistService.savePlaylist(action.playlist).pipe(
                    map((saveResponse: any) => {
                        console.log('Save playlist response: ', saveResponse);
                        return ({ type: '[Playlist Service] Load Playlist' })
                    }),
                    catchError(error => of(({ type: '[Playlist Service] Save Playlist Failure', error })))
                )
            })
        )
    );

    updatePlaylist$ = createEffect(() => 
        this.actions$.pipe(
            ofType('[Playlist Service] Update Playlist'),
            exhaustMap((action: any) => {
                // console.log('Playlist update in effect: ', action);
                return this.playlistService.updatePlaylist(action.playlist).pipe(
                    map((saveResponse: any) => {
                        console.log('Save playlist response: ', saveResponse);
                        return ({ type: '[Playlist Service] Load Playlist' })
                    }),
                    catchError(error => of(({ type: '[Playlist Service] Update Playlist Failure', error })))
                )
            })
        )
    );

    loadFilters$ = createEffect(() =>
        this.actions$.pipe(
            ofType('[Playlist Service] Load Playlist'),
            exhaustMap(() => {
                return this.playlistService.getAllPlaylist().pipe(
                    map((playlists: any) => {
                        // console.log('Playlists effects: ', playlists)
                        return ({ type: '[Playlist Service] Load Playlist Success',  playlists })
                    }),
                    catchError(error => of(({ type: '[Playlist Service] Load Playlist Failure', error })))
                )
            })
        )
    );

    // saveFilters$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType('[Filter Service] Save Filters'),
    //         exhaustMap((action: any) => {
    //             return this.filterService.save(action.filters).pipe(
    //                 map((saveResponse: any) => {
    //                     return ({ type: '[Filter Service] Save Filters Success', filters: saveResponse })
    //                 }),
    //                 catchError(error => of(({ type: '[Filter Service] Save Filters Failure', error })))
    //             )
    //         }
    //         )
    //     )
    // );

    // filterList$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType('[Filter SelectList] Filter List'),
    //         exhaustMap((action: any) => {
    //             return this.playerService.filter(action.filter).pipe(
    //                 map((audios: any) => {
    //                     return ({ type: '[Audio Service] Load Audio Success', audios })
    //                 }),
    //                 catchError(error => of(({ type: '[Audio Service] Load Audio Failure', error })))
    //             )
    //         })
    //     )
    // );

    constructor(
        private actions$: Actions,
        private playlistService: PlaylistService
    ) { }
}