import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { FileElement } from 'src/app/classes/file-element';
import { TextboxElement } from 'src/app/classes/textbox-element';
import { FormElements } from 'src/app/interfaces/form-elements';
import { IAlbum } from 'src/app/interfaces/ialbum';
import { HttpService } from '../http/http.service';
import { environment } from 'src/environments/environment';
import { TextareaElement } from 'src/app/classes/textarea-element';

@Injectable({
  providedIn: 'root'
})
export class AlbumService {
  private url: string = environment.album_service_url;
  constructor(private http: HttpService) { }

  getAudioFormElements() {

    const elements: FormElements<string>[] = [
      new TextboxElement({ key: 'name', label: 'Title', type: 'text', required: true, order: 1 }),
      new TextareaElement({ key: 'description', label: 'Description', type: 'text', required: false, order: 2 }),
      new TextboxElement({ key: 'artist', label: 'Artist', type: 'text', required: true, order: 3 }),
      new TextboxElement({ key: 'year', label: 'Year', type: 'number', required: false, order: 4 }),
      new TextboxElement({ key: 'genre', label: 'Genre', type: 'text', required: true, order: 5 }),
      new FileElement({ key: 'arts', label: 'Album Cover', type: 'file', types: '.png, .jpg, .gif', required: false, order: 6, multiple: false}),
      new FileElement({ key: 'audios', label: 'Tracks', type: 'file', types: '.mp3, .m4a', required: true, order: 7, multiple: true}),
    ];

    return of(elements.sort((a, b) => a.order - b.order));
  }

  getAll() {
    return this.http.get(`${this.url}/albums`);
  }

  get(album: IAlbum) {
    return this.http.get(`${this.url}/album/${album.id}`);
  }

  save(album: IAlbum) {
    return this.http.post(`${this.url}/album`, album);
  }

  update(album: IAlbum) {
    const {id, ...rest} = album;
    return this.http.update(`${this.url}/album`, {...rest}, id);
  }

  delete(album: IAlbum) {
    return this.http.delete(`${this.url}/album`, album.id)
  }

}
