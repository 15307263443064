import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
// import { IAudio } from 'src/app/interfaces/iaudio';
import { AudioService } from 'src/app/services/audio/audio.service';
import { MediaService } from 'src/app/services/media/media.service';
import { MusicService } from 'src/app/services/music/music.service';

@Injectable()
export class MusicEffects {
    saveMedia$ = createEffect(() =>
        this.actions$.pipe(
            ofType('[Media musicForm] Save Media'),
            exhaustMap((action: any) => {
                return this.mediaService.upload(action.media).pipe(
                    map((mediaResponse: any) => {
                        console.log('Media Response: ', mediaResponse)
                        return ({ type: '[Media Service] Media Save Success', mediaResponse })
                    }),
                    catchError(error => of(({ type: '[Media Service] Media Save Failure', error })))
                )
            }
            )
        )
    );

    deleteMedia$ = createEffect(() =>
        this.actions$.pipe(
            ofType('[Audio Service] Delete Media'),
            exhaustMap((action: any) => {
                return this.mediaService.deleteMedia(action.audio).pipe(
                    map((audio: any) => {
                        console.log('Media Response: ', audio)
                        return ({ type: '[Audio Service] Delete Audio', audio })
                    }),
                    catchError(error => of(({ type: '[Audio Service] Media Delete Failure', error })))
                )
            }
            )
        )
    );

    deleteAudio$ = createEffect(() =>
        this.actions$.pipe(
            ofType('[Audio Service] Delete Audio'),
            exhaustMap((action: any) => {
                console.log('Delete Audio Effect: ', action);
                return this.musicService.deleteMusic(action.audio).pipe(
                    map((musicResponse: any) => {
                        console.log('Music Response: ', musicResponse)
                        return ({ type: '[Audio Service] Load Audio' })
                    }),
                    catchError(error => of(({ type: '[Audio Service] Audio Delete Failure', error })))
                )
            }
            )
        )
    );

    loadAudios$ = createEffect(() =>
        this.actions$.pipe(
            ofType('[Audio Service] Load Audio'),
            exhaustMap((action) => {
                return this.musicService.getAll().pipe(
                    map((audios: any) => {
                        // console.log('Audios response: ', audios)
                        return ({ type: '[Audio Service] Load Audio Success', audios })
                    }),
                    catchError(error => of(({ type: '[Audio Service] Load Audio Failure', error })))
                )
            })
        )
    );

    constructor(
        private actions$: Actions,
        private mediaService: MediaService,
        private musicService: MusicService,
        // private audioService: AudioService
    ) { }
}