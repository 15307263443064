import { createAction, props } from '@ngrx/store';
import { IFilter } from 'src/app/interfaces/ifilter';

export const filterList = createAction(
  '[Filter SelectList] Filter List',
  props<{ filter: IFilter }>()
);

export const saveFilters = createAction(
  '[Filter Service] Save Filters',
  props<{ filters: string[] }>()
);

export const saveFiltersSuccess = createAction(
    '[Filter Service] Save Filters Success',
    props<{ filters: string[] }>()
);

export const saveFiltersFailure = createAction(
    '[Filter Service] Save Filters Failure',
    props<{ error: any }>()
);

export const loadFilters = createAction(
  '[Filter Service] Load Filters'
);

export const loadFiltersSuccess = createAction(
    '[Filter Service] Load Filters Success',
    props<{ filters: string[] }>()
);

export const loadFiltersFailure = createAction(
    '[Filter Service] Load Filters Failure',
    props<{ error: any }>()
);