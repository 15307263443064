import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, BehaviorSubject, Subject } from "rxjs";
import { IAudio } from 'src/app/interfaces/iaudio';
import { IMusic } from 'src/app/interfaces/imusic';
import { IPlaylistState, } from 'src/app/interfaces/istream-state';
import { LocalStorageService } from 'src/app/storage/local/local-storage.service';
import { MediaService } from '../media/media.service';
import { AudioStreamService } from './audio-stream.service';

@Injectable({
  providedIn: 'root'
})
export class AudioPlaylistService {
  private _current_index = 0;
   private state: IPlaylistState = {
    status: false,
    current: 0,
    previous: 0,
    next: 0,
    size: 0
   };
  private _playerlist_id = '_cma_playlist';
  private _audios!: IAudio[];
  private stateChange: BehaviorSubject<IPlaylistState> = new BehaviorSubject(this.state);

  private musicState$: Observable<IMusic> = this.store.select(state => state.music);

  set playlists(list: string[]) {
    this.localStorage.save(this._playerlist_id, list);
  }

  get playlists() {
    return this.localStorage.get(this._playerlist_id);
  }

  constructor(
    private localStorage: LocalStorageService,
    private streamService: AudioStreamService,
    private mediaService: MediaService,
    private store: Store<{ music: IMusic }>
  ) { 
    this.musicState$.subscribe((musicState: IMusic) => {
      // Map audio's to to list
      if(musicState.audios) this._audios = musicState.audios;
    });
  }

  queuePlaylist(playlists: string[]) {
    // console.log('List of songs in playlist: ', playlists)
    // Clear prev queue
    this.clearPlaylist();
    this._current_index = 0;
    this.playlists = playlists;
    this.playQueue();
  }

  playQueue() {
    console.log('Current index: ', this._current_index)
    const audio = this._audios.find((audio: IAudio) => audio.id == this.playlists[this._current_index]);
    // console.log('Audio: ', audio);
    if (audio) {
      const {artist, name: title, song: id} = audio;
      this.mediaService.getS3Url(id).subscribe((res: any) => {
        // Set playlist state
//         let isPlaylist: IPlaylistState = {status: true, current: this._current_index};
        this.state = {status: true, current: this._current_index};

        if (this.playlists.length > 0 && this._current_index > 0) this.state.previous = this._current_index - 1;
        if (this.playlists.length > 0 && this._current_index < this.playlists.length - 1) this.state.next = this._current_index + 1;
        this.state.size = this.playlists.length - 1;
        this.stateChange.next(this.state);
        // Play current audio
        this.streamService.playStream(res.url, id, title, artist, this.state).subscribe((events: any) => {

          // console.log('Playlist length: ', this.playlists.length)
          // Queue next track
          if (events.type == 'ended' && this.playlists.length > 0) {
            if (this.playlists.length -1 > this.state.current) {
              console.log('Play next track in playlist...');
              this._current_index ++;
              this.state = {status: true, current: this._current_index};
              this.stateChange.next(this.state);
              // Stop current playing file
              this.streamService.stop();
              // Loop
              this.playQueue();
            } //else this.streamService.stopAll()
          }

        });
      })
    } else throw Error('Invalid Playlist')
  }

  clearPlaylist() {
    this.localStorage.delete(this._playerlist_id);
    this.streamService.stopAll();
  }

  playNext(next: number) {
    this._current_index = next;
    this.playQueue();
  }

  playPrevious(previous: number) {
    this._current_index = previous;
    this.playQueue();
  }

  stopPlaylist() {
    this.streamService.stopAll();
    this.stateChange.next(this.state);
  }

    getState(): Observable<IPlaylistState> {
      return this.stateChange.asObservable();
    }
}
