import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpInterceptorProviders } from './interceptors';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SessionEffects } from './storage/states/session/session.effects';
import { sessionReducer } from './storage/states/session/session.reducer';
import { musicReducer } from './storage/states/music/music.reducer';
import { MusicEffects } from './storage/states/music/music.effects';
import { PlayerEffects } from './storage/states/player/player.effects';
import { playerReducer } from './storage/states/player/player.reducer';
import { filtersReducer } from './storage/states/filter/filter.reducer';
import { FiltersEffects } from './storage/states/filter/filter.effects';
import { PlaylistEffects } from './storage/states/playlist/playlist.effects';
import { playlistReducer } from './storage/states/playlist/playlist.reducer';
import { ComponentsModule } from './components/components.module';
import { albumReducer } from './storage/states/album/playlist.reducer';
import { AlbumEffects } from './storage/states/album/album.effects';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ComponentsModule,
    StoreModule.forRoot({ 
      session: sessionReducer, 
      music: musicReducer, 
      player: playerReducer, 
      filter: filtersReducer,
      playlist: playlistReducer,
      album: albumReducer
    }),
    EffectsModule.forRoot([
      SessionEffects, 
      MusicEffects, 
      PlayerEffects, 
      FiltersEffects,
      PlaylistEffects,
      AlbumEffects
    ])
  ],
  providers: [
    HttpInterceptorProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
