<div class="main-container">
    <div class="top-container">
        <!-- Top Nav ms-3 -->
        <mat-toolbar class="d-flex align-items-stretch" color="accent">
            <span class="nav-spacer"></span>
            <button (click)="miniPlayer()" routerLinkActive="active" ariaCurrentWhenActive="page" mat-button aria-label="minimize">
                <mat-icon>expand_more</mat-icon>
            </button>
        </mat-toolbar>
    </div>
    <div class="middle-container p-2">
        <!-- Default cover image -->
        <mat-card class="col-12">
            <img [src]="cover_art ? cover_art : 'assets/images/speaker.svg'" alt="default art cover" width="80%" class="rounded mx-auto d-block">
        </mat-card>

        <!-- Media Controller -->
        <mat-card class="mt-2 justify-content-between">
            <div class="row">
                <!-- Player Controlls -->
                <div class="col-sm-12 d-flex justify-content-between">
                    <div class="mt-2">
                        <button [disabled]="!enablePrev()" mat-mini-fab color="accent" (click)="prev()">
                            <mat-icon>skip_previous</mat-icon>
                        </button>
                    </div>
                    <div class="mx-2">
                        <!-- Play All -->
                        <button *ngIf="audioPlayer.stopped" [disabled]="audioPlayer.error" mat-fab color="warn">
                            <mat-icon class="player-play">play_circle</mat-icon>
                        </button>
                        <!-- Pause and play -->
                        <button *ngIf="!audioPlayer.stopped" [disabled]="audioPlayer.error" mat-fab (click)="toggleMusic()"
                            [color]="audioPlayer && audioPlayer.playing ? 'accent' : 'primary' ">
                            <mat-icon class="player-play">{{ audioPlayer && audioPlayer.playing ? 'pause' : 'play_arrow' }}
                            </mat-icon>
                        </button>
                    </div>
                    <!-- Stop -->
                    <!-- <div class="mt-2">
                        <button [disabled]="audioPlayer.stopped || audioPlayer.error" mat-mini-fab color="warn">
                            <mat-icon id="player-stop" (click)="stopPlay()">stop</mat-icon>
                        </button>
                    </div> -->
                    <div class="mt-2">
                        <button [disabled]="!enableNext()" mat-mini-fab color="accent" (click)="next()">
                            <mat-icon>skip_next</mat-icon>
                        </button>
                    </div>
                    <div class="w-100 px-2"></div>
                    <div>
                        <mat-card>{{ audioPlayer && audioPlayer.readableCurrentTime ? audioPlayer.readableCurrentTime :
                            '00:00' }}</mat-card>
                    </div>
                </div>
                <!-- Player Slider -->
                <div class="col-sm-12">
                    <mat-slider tickInterval="1" step="1" min="0" [max]="audioPlayer ? audioPlayer.duration : '0'"
                        [value]="audioPlayer ? audioPlayer.currentTime : '00.00'" (input)="seek($event)"
                        [disabled]="!audioPlayer.playing || audioPlayer.error" class="w-100"></mat-slider>
                </div>
            </div>
        </mat-card>
    </div>
    <div class="d-flex justify-content-center bottom-container p-2">
        <!-- Track Information -->
        <mat-card class="align-content-stretch col-12">
            <!-- <div class="flex-grow-1 ms-3">
                <div class="flex-grow-1 bd-highlight">{{ audioPlayer && audioPlayer._title ? audioPlayer._title : 'Unknown'}}</div>
                <div class="col-5 bd-highlight" style="font-size: 10px;">{{ audioPlayer && audioPlayer._artist ? audioPlayer._artist : 'Unknown' }}</div>
            </div> -->
            <div class="d-flex">
                <div class="p-2 flex-grow-1">
                    <div class="flex-grow-1">
                        <div class="flex-grow-1 bd-highlight">{{ audioPlayer && audioPlayer._title ? audioPlayer._title : 'Unknown'}}</div>
                        <div class="col-5 bd-highlight" style="font-size: 10px;">{{ audioPlayer && audioPlayer._artist ? audioPlayer._artist : 'Unknown' }}</div>
                    </div>
                </div>
                <div class="p-2"></div>
                <div class="p-2">
                    <button mat-mini-fab color="accent">
                        <mat-icon>thumb_up</mat-icon>
                    </button>
                </div>
            </div>
        </mat-card>
    </div>
</div>