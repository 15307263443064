import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { AlbumService } from 'src/app/services/album/album.service';
import { PlaylistService } from 'src/app/services/playlist/playlist.service';

@Injectable()
export class AlbumEffects {

    saveAlbum$ = createEffect(() => 
        this.actions$.pipe(
            ofType('[Album Component] Save Album'),
            exhaustMap((action: any) => {
                // console.log('Playlist in effect: ', action);
                return this.albumService.save(action.album).pipe(
                    map((saveResponse: any) => {
                        console.log('Save album response: ', saveResponse);
                        return ({ type: '[Album Service] Load Album' })
                    }),
                    catchError(error => of(({ type: '[Album Service] Save Album Failure', error })))
                )
            })
        )
    );

    updateAlbum$ = createEffect(() => 
        this.actions$.pipe(
            ofType('[Album Service] Update Album'),
            exhaustMap((action: any) => {
                // console.log('Playlist update in effect: ', action);
                return this.albumService.update(action.album).pipe(
                    map((saveResponse: any) => {
                        console.log('Save album response: ', saveResponse);
                        return ({ type: '[Album Service] Load Album' })
                    }),
                    catchError(error => of(({ type: '[Album Service] Update Album Failure', error })))
                )
            })
        )
    );

    loadFilters$ = createEffect(() =>
        this.actions$.pipe(
            ofType('[Album Service] Load Album'),
            exhaustMap(() => {
                return this.albumService.getAll().pipe(
                    map((albums: any) => {
                        // console.log('Albums effects: ', albums)
                        return ({ type: '[Album Service] Load Album Success',  albums })
                    }),
                    catchError(error => of(({ type: '[Album Service] Load Album Failure', error })))
                )
            })
        )
    );

    // saveFilters$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType('[Filter Service] Save Filters'),
    //         exhaustMap((action: any) => {
    //             return this.filterService.save(action.filters).pipe(
    //                 map((saveResponse: any) => {
    //                     return ({ type: '[Filter Service] Save Filters Success', filters: saveResponse })
    //                 }),
    //                 catchError(error => of(({ type: '[Filter Service] Save Filters Failure', error })))
    //             )
    //         }
    //         )
    //     )
    // );

    // filterList$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType('[Filter SelectList] Filter List'),
    //         exhaustMap((action: any) => {
    //             return this.playerService.filter(action.filter).pipe(
    //                 map((audios: any) => {
    //                     return ({ type: '[Audio Service] Load Audio Success', audios })
    //                 }),
    //                 catchError(error => of(({ type: '[Audio Service] Load Audio Failure', error })))
    //             )
    //         })
    //     )
    // );

    constructor(
        private actions$: Actions,
        private albumService: AlbumService
    ) { }
}