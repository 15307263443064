import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { of, Observable, throwError, catchError, retry } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  }),
  observe: 'response' as const,
  responseType: 'json' as const,
  reportProgress: true
}

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient) { }

  get(url: string, headers?: any) {
    const heads = headers ? headers : {'Content-Type': 'application/json'};
    return this.http.get(url, heads)
    .pipe(catchError(this.handleError));
  }

  post(url: string, body: any, headers?: any) {
    const heads = headers ? headers : {'Content-Type': 'application/json'};
    return this.http.post(url, body, {headers: heads })
    .pipe(catchError(this.handleError));
  }

  put(url: string, body: any, headers?: any) {
    const heads = headers ? headers : {'Content-Type': 'application/json'};
    return this.http.put(url, body, {headers: heads })
    .pipe(catchError(this.handleError));
  }

  update(url: string, body: any, param: string | number) {
    let _url: string = param ? `${url}/${param}` : url;
    return this.http.put(_url, body);
  } 

  delete(url: string, param: string | number) {
    let _url: string = param ? `${url}/${param}` : url;
    return this.http.delete(_url);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      // console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      // console.error(`Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    // return throwError(() => new Error('Something bad happened; please try again later.'));
    const message = error?.error?.message ? error.error.message : error.error;
    return throwError(() => new Error(error.message));
  }

  subjectToPromise(subject: Observable<any>): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        subject.subscribe((res: any) => resolve(res));
      } catch (error) {
        reject(error);
      }
    });
  }

}