import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { FileElement } from 'src/app/classes/file-element';
import { TextareaElement } from 'src/app/classes/textarea-element';
import { TextboxElement } from 'src/app/classes/textbox-element';
import { FormElements } from 'src/app/interfaces/form-elements';
import { IAudio } from 'src/app/interfaces/iaudio';
import { IMusic } from 'src/app/interfaces/imusic';
import { environment } from 'src/environments/environment';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class MusicService {

  private url: string = environment.audio_service_url;

  constructor(private http: HttpService) { }

  getMusicFormElements() {

    const elements: FormElements<string>[] = [
      new TextboxElement({ key: 'name', label: 'Title', type: 'text', required: true, order: 1 }),
      new TextareaElement({ key: 'description', label: 'Description', type: 'text', required: false, order: 2 }),
      new TextboxElement({ key: 'artist', label: 'Artist', type: 'text', required: true, order: 3 }),
      new TextboxElement({ key: 'year', label: 'Year', type: 'number', required: false, order: 5 }),
      new TextboxElement({ key: 'genre', label: 'Genre', type: 'text', required: true, order: 5 }),
      new FileElement({ key: 'audio', label: 'Media', type: 'file', types: '.mp3, .m4a', required: true, order: 6, multiple: false}),
    ];

    return of(elements.sort((a, b) => a.order - b.order));
  }

  getAll() {
    return this.http.get(`${this.url}/audios`);
  }

  getMusic(id: string) {
    return this.http.get(`${this.url}/audio/${id}`);
  }

  save(music: IMusic) {
    return this.http.post(`${this.url}/audio`, music);
  }

  deleteMusic(audio: IAudio) {
    return this.http.delete(`${this.url}/audio`, audio.id)
  }
}
