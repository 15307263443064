import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpService } from '../http/http.service';
import { tap } from 'rxjs/operators';
import { ICredentials, IUser } from 'src/app/interfaces/iuser';
import { EMPTY, Observable, of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { ILoginResponse } from 'src/app/interfaces/ilogin-response';
import { ISession } from 'src/app/interfaces/isession';
import { Store } from '@ngrx/store';
import { loginSuccess, sessionReset } from 'src/app/storage/states/session/session.actions';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthService {


  private url: string = environment.account_service_url;
  private _tokenRefreshTimer: any;
  private _sessionInactivityTimer: any;
  private _session_cookie_name = '_scma';
  api_key: string = environment.account_service_api_key;
  session$: Observable<ISession> = this.store.select(state => state.session);
  // user!: IUser;

  public get session(): ISession {
    return this.cookie.check(this._session_cookie_name)
      ? JSON.parse(this.cookie.get(this._session_cookie_name))
      : null;
  }

  public set session(session: ISession) {
    // Delete session cookie
    // this.cookie.delete(this._session_cookie_name);
    // Set session cookie
    
    // this.cookie.set(
    //   this._session_cookie_name,
    //   JSON.stringify(session),
    //   this.tokenTime(session.auth.ttl),
    // );

    // console.log('new session data: ', session)
    document.cookie = `${this._session_cookie_name}=${JSON.stringify(session)}; expires=${this.tokenTime(session.auth.ttl)}; path=/`;
  }

  constructor(
    private http: HttpService, 
    private cookie: CookieService,
    private store: Store<{ session: ISession }>,
    private router: Router
    // private state: Store<State>
  ) { }

  login(body: ICredentials) {
    return this.http.post(`${this.url}/token`, body)
    .pipe(tap((res: any) => {}));
  }

  logout(user: IUser) :Observable<any> {
    // console.log('Logout')
    return this.http.update(`${this.url}/account`, {token: ''}, user?.email)
    .pipe(tap((res: any) => {
      // Delete previous session cookie
      this.deleteSession()
      this.router.navigate(['/']);
    }))
  }

  public getSession(): Observable<ILoginResponse> {
    return this.session ? of({...this.session.user, ...this.session.auth}) : EMPTY;
  }

  // // Global session time
  // public startInactivityTimer() {
  //   // const timeout = ttl * 2;
  //   const timeout = 600000;
  //   console.log('setting inactivity timer: ', timeout);
  //   this._sessionInactivityTimer = setTimeout(() => {
  //     if (this.user) {
  //       this.logout(this.user).subscribe(); // Logout user
  //       // this.user = null; // Set user to null
  //     }
  //   }, timeout);
  // }

  public createSession(loginResponse: ILoginResponse, ) {
    const { token, ttl, ...rest } = loginResponse;
    this.session = {user: rest, auth: {token, ttl}};
    console.log('Starting session refresh timer')
    this.startRefreshTokenTimer(ttl);
    return of(this.session?.auth);
  }

  public deleteSession() {
    this.clearCookie();
    this.clearRefreshTokenTimer();
  }


  // Token refresher
  public startRefreshTokenTimer(ttl: number): void {
    const timeout = ttl - 30000;
    this._tokenRefreshTimer = setTimeout(
      () => this.refreshToken().subscribe((loginResponse: any) => {
        console.log('Session refreshed response: ', loginResponse);
        // Update session state
       if (loginResponse) this.store.dispatch(loginSuccess({loginResponse}));
      }),
      timeout
    );
  }

  public refreshToken() {
    // If current session is valid
    if (this.session?.auth) {
      return this.http.get(`${this.url}/token/refresh`)
      .pipe(tap((res: any) => {}));
    } else return EMPTY;
  }  

  public clearRefreshTokenTimer(): void {
    clearTimeout(this._tokenRefreshTimer);
  }

  public clearsessionInactivityTimer(): void {
    console.log('Clearing inactivity timer');
    clearTimeout(this._sessionInactivityTimer);
  }

  public tokenTime(ttl: number) {
    let now = new Date();
    let tt = new Date().setMilliseconds(ttl);
    console.log('Now: ', now.valueOf());
    return tt;
  }

  public clearCookie() {
      // Delete session cookie
      // this.cookie.delete(this._session_cookie_name);
      document.cookie = `${this._session_cookie_name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }

}
