import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { IAudio } from 'src/app/interfaces/iaudio';
import { MediaService } from 'src/app/services/media/media.service';
import { MusicService } from 'src/app/services/music/music.service';
// import { PlayerService } from 'src/app/services/player/player.service';

@Injectable()
export class PlayerEffects {

    // playNext$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType('[Player playAllBtn] Queue Next Audio'),
    //         exhaustMap(() => {
    //             return this.playerService.queueNextPlaylist().pipe(
    //                 map((audio: any) => {
    //                     // console.log('Audios response: ', audio)
    //                     return ({ type: '[Player Service] Queue Success', audio })
    //                 }),
    //                 catchError(error => of(({ type: '[Audio Service] Load Audio Failure', error })))
    //             )
    //         })
    //     )
    // );

    // playPrev$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType('[Player playAllBtn] Queue Prev Audio'),
    //         exhaustMap(() => {
    //             return this.playerService.queuePrevPlaylist().pipe(
    //                 map((audio: any) => {
    //                     // console.log('Audios response: ', audio)
    //                     return ({ type: '[Player Service] Queue Success', audio })
    //                 }),
    //                 catchError(error => of(({ type: '[Audio Service] Load Audio Failure', error })))
    //             )
    //         })
    //     )
    // );

    download$ = createEffect(() =>
        this.actions$.pipe(
            ofType('[Player Service] Queue Success'),
            exhaustMap((action: any) => {
                console.log('Get Media with filters: ', action);
                const audio: IAudio = action.audio;
                return this.mediaService.getMedia(audio.song).pipe(
                    map((mediaResponse: any) => ({ type: '[Player Service] Add Track', track: mediaResponse.data })),
                    catchError(error => of(({ type: '[Audio Service] Load Audio Failure', error })))
                )
            })
        )
    );

    // playlistPlay$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType('[Player Service] Add Track'),
    //         exhaustMap((action) => {
    //             return this.playerService.playlistPlay().pipe(
    //                 map(() =>  ({ type: '[Player Service] Play All Success'})),
    //                 catchError(error => of(({ type: '[Player Service] Play All Failure', error })))
    //             )
    //         })
    //     )
    // );

    // playlistExit$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType('[Session Sevice] Reset Session Success', '[Player Sevice] Reset Play All'),
    //         exhaustMap(() => {
    //             return this.playerService.playlistStop().pipe(
    //                 map((tracks) =>  {
    //                     return ({ type: '[Player Service] Reset Play All Success', tracks});
    //                 }),
    //                 catchError(error => of(({ type: '[Player Service] Reset Play All Failure', error })))
    //             )
    //         })
    //     )
    // );


    constructor(
        private actions$: Actions,
        // private playerService: PlayerService,
        private mediaService: MediaService
    ) { }
}