import { Injectable } from '@angular/core';

import { DropdownElement } from '../../classes/dropdown-element';
import { FormElements } from '../../interfaces/form-elements';
import { TextboxElement } from '../../classes/textbox-element';
import { map, Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpService } from '../http/http.service';
import { IUser } from '../../interfaces/iuser';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  user!: IUser;
  private url: string = environment.account_service_url;
  api_key: string = environment.account_service_api_key;

  constructor(private http: HttpService) {}

  // TODO: get from a remote source of question metadata
  getLoginFormElements() {

    const elements: FormElements<string>[] = [
      new TextboxElement({ key: 'email', label: 'Email', type: 'email', required: true, order: 1 }),
      new TextboxElement({ key: 'password', label: 'Password', type: 'password', value: '', required: true, order: 2 })
    ];

    return of(elements.sort((a, b) => a.order - b.order));
  }

  getRegisterFormElements() {
    const elements: FormElements<string>[] = [
      new TextboxElement({ key: 'firstname', label: 'Firstname', type: 'text', required: true, order: 1 }),
      new TextboxElement({ key: 'lastname', label: 'Lastname', type: 'text', required: true, order: 2 }),
      new TextboxElement({ key: 'email', label: 'Email', type: 'email', required: true, order: 3 }),
      new TextboxElement({ key: 'password', label: 'Password', type: 'password', value: '', required: true, order: 4 })
    ];

    return of(elements.sort((a, b) => a.order - b.order));
  }

  save(body: IUser): Observable<boolean> {
    return this.http.post(`${this.url}/account`, {...body, actions: ['POST', 'PUT', 'DELETE', 'GET']})
    .pipe(map((res: any) => {
      if (res.id) {
        this.user = res;
        return res;
      } else return false;
    }));
  }

}