import { createAction, props } from '@ngrx/store';
import { IAuth } from 'src/app/interfaces/iauth';
import { ILoginResponse } from 'src/app/interfaces/ilogin-response';
import { ISession } from 'src/app/interfaces/isession';
import { ICredentials, IUser } from '../../../interfaces/iuser';

// export const login = createAction(
//   '[Login] Login',
//   props<{ credentials: ICredentials }>()
// );

export const login = createAction(
  '[Session loginForm] Login',
  props<{ email: string; password: string }>()
);

// export const sessionRefresh = createAction(
//   '[Session Service] Refresh Session'
// );

export const loginSuccess = createAction(
    '[Session Service] Login Success',
    props<{ loginResponse: ILoginResponse }>()
);

export const loginFailure = createAction(
  '[Session Service] Login Failure'
);

export const sessionSuccess = createAction(
  '[Session Service] Auth Success',
  props<{ auth: IAuth }>()
);

export const sessionFailure = createAction(
  '[Session Service] Auth Failure'
);

export const loadSession = createAction(
  '[Session appComponent] Load Session'
);

export const logout = createAction(
  '[Session logoutBtn] Logout Session',
  props<{ user: IUser }>()
);

export const logoutFailure = createAction(
  '[Session Service] Logout Failure'
);

export const sessionReset = createAction(
  '[Session Sevice] Reset Session Success'
);