import { createAction, props } from '@ngrx/store';
import { IPlaylist } from 'src/app/interfaces/iplaylist';

export const savePlaylist = createAction(
    '[Playlist Component] Save Playlist',
    props<{ playlist: IPlaylist }>()
);

export const savePlaylistFailure = createAction(
    '[Playlist Service] Save Playlist Failure',
);

export const updatePlaylist = createAction(
    '[Playlist Service] Update Playlist',
    props<{ playlist: IPlaylist }>()
);

export const loadPlaylists = createAction(
    '[Playlist Service] Load Playlist',
);

export const loadPlaylistSuccess = createAction(
    '[Playlist Service] Load Playlist Success',
    props<{ playlists: IPlaylist[] }>()
);

export const loadPlaylistFailure = createAction(
    '[Playlist Service] Load Playlist Failure',
);