export class FormElements<T> {
    value: T | undefined;
    key: string;
    label: string;
    required: boolean;
    order: number;
    controlType: string;
    type: string;
    types: string; // Accepted file element upload types
    options: { key: string, value: string }[];
    multiple: boolean;

    constructor(options: {
        value?: T;
        key?: string;
        label?: string;
        required?: boolean;
        order?: number;
        controlType?: string;
        type?: string;
        types?: string; // Accepted file element upload types
        options?: { key: string, value: string }[];
        multiple?: boolean
    } = {}) {
        this.value = options.value;
        this.key = options.key || '';
        this.label = options.label || '';
        this.required = !!options.required;
        this.order = options.order === undefined ? 1 : options.order;
        this.controlType = options.controlType || '';
        this.type = options.type || '';
        this.types = options.types || '';
        this.options = options.options || [];
        this.multiple = !!options.multiple
    }
}
