import { createAction, props } from '@ngrx/store';
import { IAlbum } from 'src/app/interfaces/ialbum';

export const saveAlbum = createAction(
    '[Album Component] Save Album',
    props<{ album: IAlbum }>()
);

export const saveAlbumFailure = createAction(
    '[Playlist Service] Save Album Failure',
);

export const updateAlbum = createAction(
    '[Album Service] Update Album',
    props<{ album: IAlbum }>()
);

export const loadAlbums = createAction(
    '[Album Service] Load Album',
);

export const loadAlbumSuccess = createAction(
    '[Album Service] Load Album Success',
    props<{ albums: IAlbum[] }>()
);

export const loadAlbumFailure = createAction(
    '[Album Service] Load Album Failure',
);