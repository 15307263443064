import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  save(key: string, value: string | object) {
    try {
      const _value = typeof value == 'string' ? value : JSON.stringify(value);
      return localStorage.setItem(key, _value);
    } catch (error) {
      return error;
    }
  }

  get(key: string) {
    try {
      let result = localStorage.getItem(key);
      if (result) {
        return JSON.parse(result);
      }
      return null;
    } catch (error) {
      return error;
    }
  }

  delete(key: string) {
    try {
      return localStorage.removeItem(key);
    } catch (error) {
      return error;
    }
  }

  drop() {
    try {
      return localStorage.clear();
    } catch (error) {
      return error;
    }
  }
}