import { createReducer, on } from '@ngrx/store';
import { loginFailure, loginSuccess, sessionReset, sessionSuccess } from './session.actions';

export const initialState = {};

export const sessionReducer = createReducer(
  initialState,
  on(loginSuccess, (state, { loginResponse }) => {
    const {token, ttl, ...rest} = loginResponse;
    return ({...state, user: rest});
  }),
  on(sessionSuccess, (state, { auth }) => {
    console.log('State session auth: ', auth);
    return ({...state, auth: auth});
  }),
  on(loginFailure, (state) => ({...state, user: null, auth: null})),
  on(sessionReset, (state) => ({...state, user: null, auth: null}))
);