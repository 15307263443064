import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ISession } from './interfaces/isession';
import { IStreamState } from './interfaces/istream-state';
import { AudioStreamService } from './services/player/audio-stream.service';
import { loadSession } from './storage/states/session/session.actions';


@Component({
  selector: 'app-root',
  template: `
    <!-- Main display -->
    <div *ngIf="!full_player_mode">
      <router-outlet></router-outlet>
    </div>
    <div *ngIf="sessionState?.auth?.token">
      <!-- Mini Player -->
      <div [ngClass]="{'sticky' : isStickyBottomNav}" *ngIf="!full_player_mode && !playerState?.stopped">
        <mini-audio-player></mini-audio-player>
      </div>
      <!-- Main Player -->
      <div *ngIf="full_player_mode && !playerState?.stopped">
        <audio-player></audio-player>
      </div>
    </div>
  `,
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  full_player_mode!: boolean;
  playerState!: IStreamState;
  sessionState!: ISession;
  session$: Observable<ISession> = this.store.select(state => state.session);
  isStickyBottomNav: boolean = true;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isStickyBottomNav = (window.pageYOffset - window.innerHeight) <= window.innerHeight;
  }
  constructor(
    private audioStreamService: AudioStreamService,
    private store: Store<{ session: ISession }>
  ) { }

  ngOnInit() {
    this.store.dispatch(loadSession());
    this.session$.subscribe((sessionState: ISession) => {
      this.sessionState = sessionState;
      // if (!sessionState?.auth?.token) {
      //   // Clear player
      //   this.audioStreamService.stopAll();
      //   this.router.navigate(['/']); // logout user with invalid state
      // }
    });
    this.audioStreamService.getState().subscribe(state => {this.playerState = state})
    this.audioStreamService.getPlayer().subscribe(state => {this.full_player_mode = state})
  }
}