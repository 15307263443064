import { Component, OnInit } from '@angular/core';
import { IStreamState } from 'src/app/interfaces/istream-state';
import { ITrack } from 'src/app/interfaces/itrack';
import { AudioStreamService } from 'src/app/services/player/audio-stream.service';

@Component({
  selector: 'mini-audio-player',
  templateUrl: './mini-audio-player.component.html',
  styleUrls: ['./mini-audio-player.component.scss']
})
export class MiniAudioPlayerComponent implements OnInit {

  track!: ITrack;
  audioPlayer!: IStreamState;

  constructor(
    private streamService: AudioStreamService
  ) { }

  ngOnInit(): void {
    // Listen to stream state
    this.streamService.getState().subscribe(state => this.audioPlayer = state)
  }

  play(): void {
    this.streamService.play();
  }

  pause(): void {
    this.streamService.pause();
  }

  toggleMusic(): void {
    if (this.audioPlayer.playing) this.pause();
    else this.play();
  }

  stopMusic(): void {
    if (this.audioPlayer.playing) this.streamService.stop();
  }

  // Enable the full player mode
  fullPlayerMode(): void {
    this.streamService.fullPlayerMode();
  }

}
